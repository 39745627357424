
@import '~styles/mixins';
@import '~styles/variables';

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .forgotPassword {
    :hover {
      cursor: pointer;
    }
  }
}

.loginBtn {
  width: 100%;
  margin: 1em 0;
}

.createAccountBtn {
  color: #7d3fa8;
  margin-left: 0.5em;
}


.noAccount {
	margin: 40px 0;
}

.goSignupBtn {
  @include font-style(16px, $color: $th-purple);
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.goSignupText {
  @include font-style(16px);
}


//.phoneNumberSelector {
//  min-width: 80px;
//  max-width: 100px;
//}
.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile {
    display: inline-block;
  }

  .desktop {
    display: none;
  }
}
:global {
  .ant-select-dropdown {
    margin: 0;
    padding: 0;
    width: 300px!important;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum",;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
}
.tabs {
  // @include display-flex(space-between);
  @include display-flex(flex-start);
  margin-bottom: 10px;
}

.tab {
  @include font-style(16px, 600, $th-grey1, center, 21px);
  cursor: pointer;
  margin: 0 10px;

  &.active {
    @include font-style(16px, 600, $th-purple, center, 21px);
    text-decoration: underline;
  }
}
.login_top_margin{
  margin-top: 25px;
}
